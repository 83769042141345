import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import Join from '../components/Join'
import config from '../../data/SiteConfig'

const JoinPage = () => (
<Layout>
    <main>
      <Helmet title={`Join | ${config.siteTitle}`}>
	  <link 
	rel="stylesheet"
	type="text/css"
	charset="UTF-8"
	href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
	/>
	<link
	rel="stylesheet"
	type="text/css"
	charset="UTF-8"
	href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
	/>
	</Helmet>	
	<Join />
    </main>
  </Layout>
  )

  export default JoinPage