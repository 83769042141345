import React from "react"
import { StaticQuery, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import styles from './Footer.module.scss'
import config from '../../data/SiteConfig'


const sliderSettings = {
    autoplay: true,
    adaptiveHeight: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesPerRow: 2,
    Rows: 2,	
    slidesToScroll: 1,
  }



  export default function JointCarousel() {
    return (
    <StaticQuery
    query = {graphql`
    query weequery{
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(JPG)/" }
          relativeDirectory: { eq: "gallery/joinshow" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid (maxWidth: 450 maxHeight: 325) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
                } 
                }
          }
        }
      }
    }
          `}
    render={data => (
      <div>
      <Slider {...sliderSettings}>
      {data.allFile.edges.map(image => (   
            <Img fluid={image.node.childImageSharp.fluid} />
        ))}
    
       </Slider>
       </div>
    )}
    />
    )
    }
    
