import React from 'react'
import JointCarousel from './JoinCarousel'
import JointImages from './JointImages'
import Carousel from './Carousel'
// import downloadFile from 'gallery/parents-guide.pdf' 
import config from '../../data/SiteConfig'
import { Button } from 'gatsby-theme-material-ui';


const Join = () => (
  <>


 
  <JointCarousel />
  <h1>Contact Troop 622</h1>
  
   
<form method="POST" action="https://docs.google.com/forms/d/e/1FAIpQLScCbTdOkF8KUijvxQzgdAmzXkJrZT_qMThvqLrVXxIvklAepg/formResponse?embedded=true">
     
     <p>Are you interested in joining the troop, coming to one of our meetings or events, or learning more about scouting? We are waiting to hear from you!</p> 
     <p>Download our <a href={`parents-guide.pdf`} download>parent's guide.</a> Please submit the form below and we will get back to you quickly. You can also reach our scoutmaster Tom Nelson by phone at (425) 381-6418.</p>
       
            <p> <div>Your Name:</div> <input type="text" placeholder="Your Name" name="entry.54368848" required="" /><br/></p>
             <p><div>Your Email Address: </div><input type="text" name="entry.1385688473" placeholder="Email Address" required="" /><br/></p>
            <p><div>Your question/comments:</div> <input type="text" name="entry.1265763312" placeholder="Subject" /><br/></p>
           <button name="submit" type="submit" value="fav_HTML">Submit</button>                          
</form>

  

  

  </>
)

export default Join
